import InfoBox from "./boxes/InfoBox";

const getDescription = (scope) => {
  if (!scope) {
    scope = "BASIC";
  }

  switch (scope.toUpperCase()) {
    case "BASIC":
      return "do as many as you want as often as you want";
    case "DAILY":
      return "do it every day (or not)";
    case "MILESTONE":
      return "remember the completion by adding a description";
    case "DURATION":
      return "track the time spent doing something neat";
    default:
      return getDescription("BASIC");
  }
};

const CountTypeLabel = (props) => {
  return (
    <InfoBox
      title="Count&nbsp;Type"
      value={props.counttype}
      desc={getDescription(props.counttype)}
    />
  );
};

export default CountTypeLabel;
