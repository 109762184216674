const HeaderAndTag = (props) => {
  return (
    <>
      <div className="text-center">
        <h2>{props.title}</h2>
        <hr />
      </div>
      <p className="col" style={{ textAlign: "left" }}>{props.tagline}</p>
    </>
  );
};

export default HeaderAndTag;
