import { Link } from "react-router-dom";
import { darkGreen, lightGreen } from "../Colors";

const MenuItem = (props) => {
  if (props.location.pathname === props.to) {
    return (
      <div
        className="col list-group-item"
        style={{ backgroundColor: lightGreen }}
      >
        <div className="text-nowrap">
          <b style={{ color: darkGreen }}>{props.label}</b>
        </div>
      </div>
    );
  }

  return (
    <Link to={props.to} className="col list-group-item list-group-item-action">
      <div className="text-nowrap">{props.label}</div>
    </Link>
  );
};

const MenuBar = (props) => {
  return (
    <div className="row">
      {props.jwtToken !== "" && (
        <div className="col">
          <nav>
            <div className="row list-group list-group-horizontal">
              <MenuItem to="/" label="Home" location={props.location} />
              <MenuItem
                to="/current"
                label="Current Counts"
                location={props.location}
              />
              <MenuItem to="/today" label="Today" location={props.location} />
              <MenuItem to="/streaks" label="Streaks" location={props.location} />
              <MenuItem
                to="/counters"
                label="Counters"
                location={props.location}
              />
              <MenuItem to="/goals" label="Goals" location={props.location} />
              <MenuItem
                to="/progress"
                label="Goal Progress"
                location={props.location}
              />
            </div>
          </nav>
        </div>
      )}
    </div>
  );
};

export default MenuBar;
