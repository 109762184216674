import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { darkGreen, lightGreen } from "./Colors";
import HeaderAndTag from "./form/HeaderAndTag";
import ToggleSwitch from "./form/ToggleSwitch";

const Goals = () => {
  const [goals, setGoals] = useState([]);
  const [hideCompleted, setHideCompleted] = useState(true);

  const { jwtToken } = useOutletContext();

  const navigate = useNavigate();

  const handleToggle = () => {
    setHideCompleted(!hideCompleted);
  };

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/login");
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/goals`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setGoals(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [jwtToken, navigate]);

  return (
    <div>
      <HeaderAndTag title="Goals" />

      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Target</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          {goals.map(
            (g) =>
              (Date.parse(g.end) > Date.now() || !hideCompleted) && (
                <tr key={g.id}>
                  <td>
                    <Link to={`/goals/${g.id}`}>{g.name}</Link>
                  </td>
                  <td>{g.targetCount}</td>
                  <td>{new Date(g.end).toLocaleDateString()}</td>
                  <td className="text-end"></td>
                </tr>
              )
          )}
        </tbody>
      </table>

      <div className="border">
        <ToggleSwitch
          id="thisPastToggle"
          isOn={hideCompleted}
          handleToggle={handleToggle}
          onColor={lightGreen}
          offColor={darkGreen}
          onLabel="Hide Completed"
          offLabel="Show Completed"
        />
      </div>

      <hr />
      <Link to={`/newgoal`} className="btn btn-primary">
        Add New Goal
      </Link>
    </div>
  );
};

export default Goals;
