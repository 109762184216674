import { EditIcon, InfoIcon } from "../form/StatusCircles";

const InfoBox = (props) => {
  const title = props.title;
  const value = props.value ? props.value : "";
  const desc = props.desc;
  const edit = props.editFunc ?? null;

  return (
    <div
      className="p-1"
      style={{ display: "inline-block", borderWidth: "3px" }}
    >
      <div className="row">
        <div className="col small fw-bold d-flex">{title}:</div>
        <div className="col d-flex justify-content-end pt-1 pe-3" title={desc}>
          {desc && <InfoIcon size="20" />}
        </div>
      </div>
      {value.length && value.length > 15 ? (
        <div className="h3 text-capitalize ps-4 pe-4 pb-4 pt-2">
          {value.toLowerCase ? value.toLowerCase() : value}
        </div>
      ) : (
        <div className="h1 text-capitalize ps-4 pe-4 pb-4 pt-2">
          {value.toLowerCase ? value.toLowerCase() : value}
          &nbsp;
          {edit ? (
            <a
              href="#!"
              className="btn btn-outline-info ms-2"
              onClick={edit}
              title={``}
            >
              <EditIcon size="20" />
            </a>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default InfoBox;
