import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './components/ErrorPage';
import Home from './components/Home';
import Login from './components/Login'
import Current from './components/Current';
import Counters from './components/Counters';
import Today from './components/Today';
import Register from './components/Register';
import Goals from './components/Goals';
import Goal from './components/Goal';
import NewGoal from './components/NewGoal';
import GoalProgress from './components/GoalProgress';
import NewCounter from './components/NewCounter';
import History from './components/History';
import Counter from './components/Counter';
import PublicCurrent from './components/PublicCurrent';
import Streaks from './components/Streaks';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {index: true, element: <Home />},
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/current",
        element: <Current />,
      },
      {
        path: "/counters",
        element: <Counters />,
      },
      {
        path: "/counters/:id",
        element: <Counter />,
      },
      {
        path: "/newcounter",
        element: <NewCounter />,
      },
      {
        path: "/goals",
        element: <Goals />,
      },
      {
        path: "/progress",
        element: <GoalProgress />,
      },
      {
        path: "/goals/:id",
        element: <Goal />,
      },
      {
        path: "/newgoal",
        element: <NewGoal />,
      },
      {
        path: "/today",
        element: <Today />,
      },
      {
        path: "/history/:id",
        element: <History />,
      },
      {
        path: "/public/:id/counts",
        element: <PublicCurrent />,
      },
      {
        path: "/streaks",
        element: <Streaks />,
      }
    ]
  },
]
)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();