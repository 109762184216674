import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import HeaderAndTag from "./form/HeaderAndTag";
import { StatusCompletedSuccessfullyIcon } from "./form/StatusCircles";
import { darkGreen, lightGreen, red } from "./Colors";
import DurationInput from "./DurationInput";
import withReactContent from "sweetalert2-react-content";
import ToggleSwitch from "./form/ToggleSwitch";

const Today = () => {
  const [counts, setCounts] = useState([]);
  const [showHidden, setShowHidden] = useState(false);
  const { jwtToken } = useOutletContext();

  const navigate = useNavigate();

  const MySwal = withReactContent(Swal);

  const handleHiddenToggle = () => {
    setShowHidden(!showHidden);
  };

  const updateCounts = () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/counts`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCounts(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addCountEntry = (counterId, value, description) => {
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "PUT",
      headers: headers,
    };

    const desc = description ? encodeURIComponent(description) : "";

    const url = description
      ? `${process.env.REACT_APP_BACKEND}/counters/${counterId}/${value}?description=${desc}`
      : `${process.env.REACT_APP_BACKEND}/counters/${counterId}/${value}`;

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.errorCode) {
          console.log(data.error);
        } else {
          updateCounts();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const recordValue = (event) => {
    event.preventDefault();

    const counterId = event.target.id;
    const counterName = event.target.firstChild.textContent;
    const yesterday = event.target.childNodes[1].textContent;

    Swal.fire({
      title: `<h1>${counterName}</h1>How many?`,
      input: "number",
      inputValue: yesterday,
      showCancelButton: true,
      confirmButtonText: "OK",
      confirmButtonColor: darkGreen,
      cancelButtonColor: red,
      showLoaderOnConfirm: true,
      preConfirm: (val) => {
        if (val && val !== 0 && val !== "0") {
          return val;
        } else {
          Swal.showValidationMessage("Set a non-zero value");
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        addCountEntry(counterId, result.value);
      }
    });
  };

  const recordMilestone = (event) => {
    event.preventDefault();

    const counterId = event.target.id;
    const counterName = event.target.firstChild.textContent;

    Swal.fire({
      title: `<h1>${counterName}</h1>Describe Milestone`,
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "+1",
      confirmButtonColor: darkGreen,
      cancelButtonColor: red,
      showLoaderOnConfirm: true,
      preConfirm: (val) => {
        if (val) {
          return val;
        } else {
          Swal.showValidationMessage("Milestone description missing");
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        addCountEntry(counterId, 1, result.value);
      }
    });
  };

  const recordDaily = (event) => {
    event.preventDefault();

    const counterId = event.target.id;
    const counterName = event.target.firstChild.textContent;

    Swal.fire({
      title: `<h1>${counterName}</h1>Did you do it today?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: darkGreen,
      cancelButtonColor: red,
      confirmButtonText: "Yes!",
      cancelButtonText: "Not yet",
    }).then((result) => {
      if (result.isConfirmed) {
        addCountEntry(counterId, 1);
      }
    });
  };

  const formatDuration = (duration) => {
    if (duration < 60) {
      return `${duration}mins`;
    }
    const hours = Math.floor(duration / 60);
    const mins = duration % 60;
    return `${hours}hr ${mins}min`;
  };

  const recordDuration = (event) => {
    event.preventDefault();

    const counterId = event.target.id;
    const counterName = event.target.firstChild.textContent;

    let duration = 0;

    const childToParent = (childdata) => {
      duration = childdata;
    };

    MySwal.fire({
      title: (
        <>
          <h1>{counterName}</h1>
          <DurationInput childToParent={childToParent} />
        </>
      ),
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: darkGreen,
      cancelButtonColor: red,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      preConfirm: () => {
        if (duration > 0) {
          return duration;
        } else {
          Swal.showValidationMessage("Add some time");
        }
      },
    }).then((result) => {
      if (result.isConfirmed && duration > 0) {
        addCountEntry(counterId, duration);
      }
    });
  };

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/login");
    }

    updateCounts();
  }, [jwtToken, navigate]);

  const getCounterEntrySnippet = (counter) => {
    if (!showHidden && counter.scope === "HIDDEN") {
      return <tr key={counter.id} style={{display: "none"}} />;
    }

    if (counter.type === "DAILY") {
      return (
        <tr key={counter.id}>
          <td>{counter.name}</td>
          <td>
            {counter.today > 0 ? (
              <StatusCompletedSuccessfullyIcon size={30} />
            ) : (
              0
            )}
          </td>
          <td>
            {counter.today <= 0 && (
              <a
                href="#!"
                id={counter.id}
                className="btn btn-primary ms-2"
                onClick={recordDaily}
              >
                <div hidden={true}>{counter.name}</div>
                Mark Done
              </a>
            )}
          </td>
        </tr>
      );
    }

    if (counter.type === "MILESTONE") {
      return (
        <tr key={counter.id}>
          <td>{counter.name}</td>
          <td>{counter.today}</td>
          <td>
            <div
              id={counter.id}
              className="btn btn-primary"
              onClick={recordMilestone}
            >
              <div hidden={true}>{counter.name}</div>
              Record Milestone
            </div>
          </td>
        </tr>
      );
    }

    if (counter.type === "DURATION") {
      return (
        <tr key={counter.id}>
          <td>{counter.name}</td>
          <td>{formatDuration(counter.today)}</td>
          <td>
            <div
              id={counter.id}
              className="btn btn-primary"
              onClick={recordDuration}
            >
              <div hidden={true}>{counter.name}</div>
              Record Duration
            </div>
          </td>
        </tr>
      );
    }

    return (
      <tr key={counter.id}>
        <td>{counter.name}</td>
        <td>{counter.today}</td>
        <td>
          <div
            id={counter.id}
            className="btn btn-primary"
            onClick={recordValue}
          >
            <div hidden={true}>{counter.name}</div>
            <div hidden={true}>{counter.yesterday ? counter.yesterday : 1}</div>
            Record Value
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div>
      <HeaderAndTag title="Today" />

      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Counter</th>
            <th>Today</th>
          </tr>
        </thead>
        <tbody>{counts.map((c) => getCounterEntrySnippet(c))}</tbody>
      </table>
      <div style={{display: "none"}} className="border">
        <ToggleSwitch
          id="showHiddenToggle"
          isOn={showHidden}
          handleToggle={handleHiddenToggle}
          onColor={lightGreen}
          offColor={darkGreen}
          onLabel="Show Hidden"
          offLabel="Hide Hidden"
        />
      </div>
    </div>
  );
};

export default Today;
