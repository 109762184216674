import InfoBox from "../boxes/InfoBox";

const getDescription = (scope) => {
  if (!scope) {
    scope = "Private";
  }

  switch (scope.toUpperCase()) {
    case "PUBLIC":
      return "people can see it if they know where to look";
    case "PRIVATE":
      return "for your eyes only";
    case "HIDDEN":
      return "for your eyes only if you know where to look";
    case "GROUP":
      return "only for you and your posse";
    default:
      return getDescription("PRIVATE");
  }
};

const ScopeLabel = (props) => {
  return (
    <InfoBox
      title="Scope"
      value={props.scope}
      desc={getDescription(props.scope)}
      editFunc={props.editFunc}
    />
  );
};

export default ScopeLabel;
