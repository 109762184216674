import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Input from "./form/Input";
import Select from "./form/Select";

const typeBasic = {
  id: "BASIC",
  value: "Basic Count",
  desc: "Basic Count is a simple counter that keeps track of the number of times you do something.  For example, counting the number of pushups.",
};

const typeDaily = {
  id: "DAILY",
  value: "Daily",
  desc: "A Daily counter is used to track things that you either do or do not do on any given day.  For example, writing in a journal.",
};

const typeMilestone = {
  id: "MILESTONE",
  value: "Milestone",
  desc: "Milestones are used to record things that may be completed at irregulate, non-daily intervals.  For example, when finished reading a book.",
};

const typeDuration = {
  id: "DURATION",
  value: "Duration",
  desc: "Duration counters are used to record the amount of time spent doing something during the day.  For example, the time spent practicing an instrument.",
};

const countTypes = [];
countTypes.push(typeBasic, typeDaily, typeMilestone, typeDuration);

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

function getColSize(screenWidth) {
  if (screenWidth < 400) {
    return "col-0"
  }
  if (screenWidth < 800) {
    return "col-1"
  }
  if (screenWidth < 1200) {
    return "col-2"
  }
  if (screenWidth < 1400) {
  return "col-3"
  }
  return "col-4"
}

const NewCounter = () => {
  const [counterName, setCounterName] = useState();
  const [typeIndex, setTypeIndex] = useState(0);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [colSize, setColSize] = useState(getColSize(getWindowSize().innerWidth))

  const [errors, setErrors] = useState([]);

  const { jwtToken } = useOutletContext();
  const { setAlertClassName } = useOutletContext();
  const { setAlertMessage } = useOutletContext();

  const navigate = useNavigate();

  useEffect(() => {
    function handleWindowResize() {
      const sz = getWindowSize()
      setWindowSize(sz);
      setColSize(getColSize(sz.innerWidth))
    }

    console.log(windowSize.innerWidth + " - " + colSize)

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [colSize, windowSize.innerWidth]);


  const hasError = (key) => {
    var result = errors.filter((obj) => {
      return obj.fieldname === key;
    });
    return result.length > 0;
  };

  const getErrorMessage = (key) => {
    var result = errors.filter((obj) => {
      return obj.fieldname === key;
    });
    if (result.length === 0) {
      return "";
    }
    return result[0].message;
  };

  const handleTypeChange = (event) => {
    const selectedIndex = event.target.options.selectedIndex;
    setTypeIndex(selectedIndex);
  };

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/login");
    }
  }, [jwtToken, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let errors = [];

    if (!counterName || counterName === "") {
      errors.push({ fieldname: "name", message: "Please enter a name" });
    }

    setErrors(errors);

    if (errors.length > 0) {
      return false;
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    // build the request payload
    let payload = {
      name: counterName,
      type: countTypes[typeIndex].id,
    };

    const requestOptions = {
      method: "POST",
      headers: headers,
      credentials: "include",
      body: JSON.stringify(payload),
    };

    fetch(`${process.env.REACT_APP_BACKEND}/counters`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.errorCode) {
          setAlertClassName("alert-danger");
          setAlertMessage(`${data.message} (${data.errorCode})`);
        } else {
          navigate("/counters");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h2>New Counter</h2>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className={colSize} />
          <div className="col">
            <Input
              title={"Name"}
              className={"form-control"}
              type={"text"}
              name={"name"}
              onChange={(event) => setCounterName(event.target.value)}
              errorDiv={hasError("name") ? "text-danger" : "d-none"}
              errorMsg={getErrorMessage("name")}
            ></Input>
          </div>
          <div className={colSize} />
        </div>

        <div className="row">
        <div className={colSize} />
          <div className="col">
            <Select
              title={"Counter Type"}
              name={"counterType"}
              options={countTypes}
              onChange={handleTypeChange}
              errorDiv="d-none"
              description={countTypes[typeIndex].desc}
            />
          </div>
          <div className={colSize} />
        </div>

        <hr />

        <button className="btn btn-primary">Save</button>
      </form>
    </div>
  );
};

export default NewCounter;
