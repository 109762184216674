import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Swal from "sweetalert2";
import InfoBox from "./boxes/InfoBox";
import Card from "./cards/Card";
import CardGrid from "./cards/CardGrid";
import { darkGreen, red } from "./Colors";
import CountTypeLabel from "./CountTypeLabel";
import { DeleteIcon, DomapIcon, GraphIcon, HistoryIcon } from "./form/StatusCircles";
import ScopeLabel from "./scope/ScopeLabel";

const Counter = () => {
  const [counter, setCounter] = useState({});
  let { id } = useParams();
  const { jwtToken } = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/login");
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/counters/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCounter(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, jwtToken, navigate]);

  const confirmDelete = (event) => {
    const goalId = event.target.id
      ? event.target.id
      : event.target.ownerSVGElement.id;

    Swal.fire({
      title: "Delete counter?",
      text: "You cannot undo this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: darkGreen,
      cancelButtonColor: red,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Authorization", "Bearer " + jwtToken);

        const requestOptions = {
          method: "DELETE",
          headers: headers,
        };

        fetch(
          `${process.env.REACT_APP_BACKEND}/counters/${goalId}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              console.log(data.error);
            } else {
              navigate("/counters");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const changeScope = (event) => {
    event.preventDefault();

    const inputOptions =  {
          'public': 'Public',
          'private': 'Private',
          'hidden': 'Hidden'
        };
  
      Swal.fire({
        title: "Select Scope",
        text: "You cannot undo this action!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: darkGreen,
        cancelButtonColor: red,
        input: 'radio',
        inputOptions: inputOptions,
        inputValue: 'private'
            }).then((result) => {
        if (result.isConfirmed) {
          let headers = new Headers();
          headers.append("Authorization", "Bearer " + jwtToken);
  
          const requestOptions = {
            method: "PUT",
            headers: headers,
          };

  
          fetch(
            `${process.env.REACT_APP_BACKEND}/counters/${counter.id}/scope/${result.value}`,
            requestOptions
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.error) {
                console.log(data.error);
              } else {
                setCounter(data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    };

  const openGraphInNewTab = (event) => {
    openInNewTab(event, "graph")
  };

  const openDomapInNewTab = (event) => {
    openInNewTab(event, "domap")
  };

  const openInNewTab = (event, lastpart) => {
    event.preventDefault();

    if (jwtToken === "") {
      navigate("/login");
    }

    if (!event.target.id) {
      return;
    }

    const url = `${process.env.REACT_APP_BACKEND}/counts/${event.target.id}/${lastpart}`;

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        let newWindow = window.open();
        newWindow.document.write(data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h2 className="ps-5 mb-4 mt-2 float-left d-flex">Counter</h2>

      <div className="ps-5">
        <CardGrid>
          <>
            <Card key="name">
              <InfoBox title="Name" value={counter.name} />
            </Card>

            <Card key="scope">
              <ScopeLabel scope={counter.scope} editFunc={changeScope}/>
            </Card>
            <Card key="counttype">
              <CountTypeLabel counttype={counter.type} />
            </Card>
            <Card key="history">
              <InfoBox
                title="History"
                desc="See all recorded count entries"
                value={
                  <Link to={`/history/${counter.id}`}>
                    <HistoryIcon size="50" />
                  </Link>
                }
              />
            </Card>
            <Card key="graph">
              <InfoBox
                title="Graph"
                desc="Show a graph of entries over time"
                value={
                  <a
                    onClick={openGraphInNewTab}
                    href="/"
                    id={counter.id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <GraphIcon id={counter.id} size="50" />
                  </a>
                }
              />
            </Card>
            <Card key="domap">
              <InfoBox
                title="Do&nbsp;Map"
                desc="Show days when counts were recorded"
                value={
                  <a
                    onClick={openDomapInNewTab}
                    href="/"
                    id={counter.id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DomapIcon id={counter.id} size="50" />
                  </a>
                }
              />
            </Card>
            <Card key="delete">
              <InfoBox
                title="Delete"
                desc="think twice before deleting a counter"
                value={
                  <a
                    href="#!"
                    id={counter.id}
                    className="btn btn-outline-danger ms-2"
                    onClick={confirmDelete}
                    title={`Delete "${counter.name}" Goal`}
                  >
                    <DeleteIcon id={counter.id} size="50" />
                  </a>
                }
              />
            </Card>
          </>
        </CardGrid>
      </div>
    </div>
  );
};

export default Counter;
