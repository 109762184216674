import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import HeaderAndTag from "./form/HeaderAndTag";
import { DomapIcon, GraphIcon, HistoryIcon } from "./form/StatusCircles";
import ToggleSwitch from "./form/ToggleSwitch";
import { darkGreen, lightGreen } from "./Colors";

const Counters = () => {
  const [counts, setCounts] = useState([]);
  const [showHidden, setShowHidden] = useState(false);
  const { jwtToken } = useOutletContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/login");
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/counts`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCounts(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [jwtToken, navigate]);

  const handleHiddenToggle = () => {
    setShowHidden(!showHidden);
  };

  const openGraphInNewTab = (event) => {
    openInNewTab(event, "graph");
  };

  const openDomapInNewTab = (event) => {
    openInNewTab(event, "domap");
  };

  const openInNewTab = (event, lastpart) => {
    event.preventDefault();

    if (jwtToken === "") {
      navigate("/login");
    }

    if (!event.target.id) {
      return;
    }

    const url = `${process.env.REACT_APP_BACKEND}/counts/${event.target.id}/${lastpart}`;

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        let newWindow = window.open();
        newWindow.document.write(data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <HeaderAndTag title="Counters" />

      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Counter</th>
            <th>Graph</th>
            <th>History</th>
            <th>Domap</th>
          </tr>
        </thead>
        <tbody>
          {counts.map((c) =>
            !showHidden && c.scope === "HIDDEN" ? (
              <tr key={c.id} style={{display: "none"}} />
            ) : (
              <tr key={c.id}>
                <td>
                  <Link to={`/counters/${c.id}`}>{c.name}</Link>
                </td>

                <td>
                  {c.pastYear > 0 && (
                    <a
                      onClick={openGraphInNewTab}
                      href="/"
                      id={c.id}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <GraphIcon id={c.id} />
                    </a>
                  )}
                </td>
                <td>
                  <Link to={`/history/${c.id}`}>
                    <HistoryIcon />
                  </Link>
                </td>
                <td>
                  {c.pastYear > 0 && (
                    <a
                      onClick={openDomapInNewTab}
                      href="/"
                      id={c.id}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <DomapIcon id={c.id} />
                    </a>
                  )}
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>

      <hr />
      <Link to={`/newcounter`} className="btn btn-primary">
        Add New Counter
      </Link>

      <div className="border">
        <ToggleSwitch
          id="showHiddenToggle"
          isOn={showHidden}
          handleToggle={handleHiddenToggle}
          onColor={lightGreen}
          offColor={darkGreen}
          onLabel="Show Hidden"
          offLabel="Hide Hidden"
        />
      </div>
    </div>
  );
};

export default Counters;
