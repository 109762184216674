import { darkGreen, lightGreen } from "../Colors";

const TitleBanner = (props) => {
  return (
    <div
      className="row"
      style={{
        backgroundColor: lightGreen,
        border: `5px solid ${darkGreen}`,
        borderTop: `5px solid ${darkGreen}`,
        borderLeft: "none",
        borderRight: "none",
      }}
    >
      <h1 style={{ color: darkGreen, paddingTop: "8px" }}>
        Better by December
      </h1>
    </div>
  );
};

export default TitleBanner;
