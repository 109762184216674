import { Link, useOutletContext } from "react-router-dom";
import HeaderAndTag from "./form/HeaderAndTag";

const Home = () => {
  const { jwtToken } = useOutletContext();

  return (
    <>
      <HeaderAndTag
        title="Start now to be Better by December"
        tagline="Every January, many people make resolutions for the New Year. Goals and
        resolutions are more likely to be achieved if measured and tracked. The
        purpose of this site is to track progress so that goals can be met and
        resolutions accomplished. By doing this, anyone can be Better by
        December."
      />

      {jwtToken === "" && (
        <>
          <h1>
            <Link to="/login">
              <span className="badge bg-success">Login</span>
            </Link>
          </h1>
          <h3>
            <Link to="/register">
              <span className="badge bg-primary">Register</span>
            </Link>
          </h3>
        </>
      )}
    </>
  );
};

export default Home;
