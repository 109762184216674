import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { darkGreen, lightGreen } from "./Colors";
import HeaderAndTag from "./form/HeaderAndTag";
import { DomapIcon, GraphIcon } from "./form/StatusCircles";
import ToggleSwitch from "./form/ToggleSwitch";
import { HistoryIcon } from "./form/StatusCircles";

const Current = () => {
  const [counts, setCounts] = useState([]);
  const [thisPast, setThisPast] = useState(true);
  const [showHidden, setShowHidden] = useState(false);
  const { jwtToken } = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/login");
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/counts`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCounts(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [jwtToken, navigate]);

  const handleToggle = () => {
    setThisPast(!thisPast);
  };

  const handleHiddenToggle = () => {
   setShowHidden(!showHidden);
  };

  const openGraphInNewTab = (event) => {
    openInNewTab(event, "graph");
  };

  const openDomapInNewTab = (event) => {
    openInNewTab(event, "domap");
  };

  const openInNewTab = (event, lastpart) => {
    event.preventDefault();

    if (jwtToken === "") {
      navigate("/login");
    }

    if (!event.target.id) {
      return;
    }

    const url = `${process.env.REACT_APP_BACKEND}/counts/${event.target.id}/${lastpart}`;

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        let newWindow = window.open();
        newWindow.document.write(data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <HeaderAndTag title="Current Counts" />
      {!thisPast && (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Counter</th>
              <th>Past Day</th>
              <th>Past Week</th>
              <th>Past Month</th>
              <th>Past Year</th>
            </tr>
          </thead>
          <tbody>
            {counts.map((c) =>
              !showHidden && c.scope === "HIDDEN" ? (
                <></>
              ) : (
                <tr key={c.id}>
                  <td>{c.name}</td>
                  <td>{c.pastDay}</td>
                  <td>{c.pastWeek}</td>
                  <td>{c.pastMonth}</td>
                  <td>{c.pastYear}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      )}
      {thisPast && (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Counter</th>
              <th>Today</th>
              <th>This Week</th>
              <th>This Month</th>
              <th>This Year</th>
            </tr>
          </thead>
          <tbody>
            {counts.map((c) =>
              !showHidden && c.scope === "HIDDEN" ? (
                <tr key={c.id} style={{display: "none"}} />
              ) : (
                <tr key={c.id}>
                  <td>
                    {c.name}{" "}
                    {c.type === "MILESTONE" ? (
                      <Link to={`/history/${c.id}`}>
                        <HistoryIcon />
                      </Link>
                    ) : (
                      <a
                        onClick={
                          c.type === "DAILY"
                            ? openDomapInNewTab
                            : openGraphInNewTab
                        }
                        href="/"
                        id={c.id}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {c.type === "DAILY" ? (
                          <DomapIcon id={c.id} />
                        ) : (
                          <GraphIcon id={c.id} />
                        )}
                      </a>
                    )}
                  </td>
                  <td>{c.today}</td>
                  <td>{c.thisWeek}</td>
                  <td>{c.thisMonth}</td>
                  <td>{c.thisYear}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      )}

      <div className="border">
        <ToggleSwitch
          id="thisPastToggle"
          isOn={thisPast}
          handleToggle={handleToggle}
          onColor={lightGreen}
          offColor={darkGreen}
          onLabel="This"
          offLabel="Past"
        />
        {/* <ToggleSwitch
          id="showHiddenToggle"
          isOn={showHidden}
          handleToggle={handleHiddenToggle}
          onColor={lightGreen}
          offColor={darkGreen}
          onLabel="Show Hidden"
          offLabel="Hide Hidden"
        /> */}
      </div>
    </div>
  );
};

export default Current;
