import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { darkGreen } from "./Colors";
import Input from "./form/Input";
import Select from "./form/Select";

const NewGoal = () => {
  const [goal, setGoal] = useState({
    name: "",
    start: `${new Date().getFullYear()}-01-01`,
    end: `${new Date().getFullYear()}-12-31`,
    targetCount: 365,
  });
  const [counters, setCounters] = useState({});
  const [errors, setErrors] = useState([]);

  const { jwtToken } = useOutletContext();
  const { setAlertClassName } = useOutletContext();
  const { setAlertMessage } = useOutletContext();

  const navigate = useNavigate();

  const hasError = (key) => {
    var result = errors.filter((obj) => {
      return obj.fieldname === key;
    });
    return result.length > 0;
  };

  const getErrorMessage = (key) => {
    var result = errors.filter((obj) => {
      return obj.fieldname === key;
    });
    if (result.length === 0) {
      return "";
    }
    return result[0].message;
  };

  const handleChange = () => (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setGoal({
      ...goal,
      [name]: value,
    });
  };

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/login");
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/counts`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        const transformed = data.map((c, index) => ({
          id: c.id,
          value: c.name,
        }));

        setCounters(transformed);
      })

      .catch((err) => {
        console.log(err);
      });

    if (counters.length === 0) {
      Swal.fire({
        title: "No Counters",
        text: "You must create a counter before creating goals",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: darkGreen,
        confirmButtonText: "Ok",
      }).then(navigate("/counters"));
    }
  }, [counters.length, jwtToken, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!goal.counterId) {
      goal.counterId = counters[0].id;
    }

    let errors = [];

    if (goal.name === "") {
      errors.push({ fieldname: "name", message: "Please enter a name" });
    }

    if (goal.start === "") {
      errors.push({ fieldname: "start", message: "Please enter a start date" });
    }

    if (goal.end === "") {
      errors.push({ fieldname: "end", message: "Please enter a end date" });
    }

    if (goal.start !== "" && goal.end !== "" && goal.start > goal.end) {
      errors.push({
        fieldname: "end",
        message: "End date must be after start date",
      });
    }

    if (goal.targetCount === "") {
      errors.push({
        fieldname: "targetCount",
        message: "Please enter a targetCount",
      });
    }

    setErrors(errors);

    if (errors.length > 0) {
      return false;
    }

    // passed validation, so save changes
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestBody = {
      name: goal.name,
      start: goal.start,
      end: goal.end,
      counterId: goal.counterId,
    };

    requestBody.targetCount = parseInt(goal.targetCount, 10);

    let requestOptions = {
      body: JSON.stringify(requestBody),
      method: "POST",
      headers: headers,
      credentials: "include",
    };

    fetch(`${process.env.REACT_APP_BACKEND}/goals`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.errorCode) {
          setAlertClassName("alert-danger");
          setAlertMessage(`${data.message} (${data.errorCode})`);
        } else {
          navigate("/goals");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h2>New Goal</h2>

      <form onSubmit={handleSubmit}>
        <Input
          title={"Name"}
          className={"form-control"}
          type={"text"}
          name={"name"}
          value={goal.name}
          onChange={handleChange("name")}
          errorDiv={hasError("name") ? "text-danger" : "d-none"}
          errorMsg={getErrorMessage("name")}
        ></Input>

        <div className="row">
          <div className="col">
            <Input
              title={"Start Date"}
              className={"form-control"}
              type={"date"}
              name={"start"}
              value={goal.start}
              onChange={handleChange("start")}
              errorDiv={hasError("start") ? "text-danger" : "d-none"}
              errorMsg={getErrorMessage("start")}
            ></Input>
          </div>
          <div className="col">
            <Input
              title={"End Date"}
              className={"form-control"}
              type={"date"}
              name={"end"}
              value={goal.end}
              onChange={handleChange("end")}
              errorDiv={hasError("end") ? "text-danger" : "d-none"}
              errorMsg={getErrorMessage("end")}
            ></Input>
          </div>
        </div>

        {counters.length && (
          <Select
            title={"Counter"}
            name={"counterId"}
            options={counters}
            onChange={handleChange("counterId")}
            errorMsg={"Please choose"}
            errorDiv={hasError("counterId") ? "text-danger" : "d-none"}
          />
        )}

        <Input
          title={"Target Count"}
          className={"form-control"}
          type={"number"}
          name={"targetCount"}
          value={goal.targetCount}
          onChange={handleChange("targetCount")}
          errorDiv={hasError("targetCount") ? "text-danger" : "d-none"}
          errorMsg={getErrorMessage("targetCount")}
        ></Input>

        <hr />

        <button className="btn btn-primary">Save</button>
      </form>
    </div>
  );
};

export default NewGoal;
