import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { darkGreen, lightGreen } from "./Colors";
import HeaderAndTag from "./form/HeaderAndTag";
import { DomapIcon } from "./form/StatusCircles";
import ToggleSwitch from "./form/ToggleSwitch";

const Streaks = () => {
  const [streaks, setStreaks] = useState([]);
  const [showHidden, setShowHidden] = useState(false);
  const { jwtToken } = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/login");
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/streaks`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setStreaks(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [jwtToken, navigate]);

  const handleHiddenToggle = () => {
    setShowHidden(!showHidden);
  };

  const openDomapInNewTab = (event) => {
    openInNewTab(event, "domap");
  };

  const openInNewTab = (event, lastpart) => {
    event.preventDefault();

    if (jwtToken === "") {
      navigate("/login");
    }

    if (!event.target.id) {
      return;
    }

    const url = `${process.env.REACT_APP_BACKEND}/counts/${event.target.id}/${lastpart}`;

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        let newWindow = window.open();
        newWindow.document.write(data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <HeaderAndTag title="Current Counts" />
      {(
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Counter</th>
              <th>Current Streak</th>
              <th>Longest Streak</th>
            </tr>
          </thead>
          <tbody>
            {streaks.map((c) =>
              !showHidden && c.scope === "HIDDEN" ? (
                <tr key={c.id} style={{display: "none"}} />
              ) : (
                <tr key={c.id}>
                  <td>
                    {c.name}{" "}
                      <a
                        onClick={openDomapInNewTab}
                        href="/"
                        id={c.id}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DomapIcon id={c.id} />
                      </a>
                  </td>
                  <td>{c.currentStreak}{(!c.doneToday && c.currentStreak > 0) && '*'}</td>
                  <td>{c.longestStreak}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      )}

      <div className="border">
        * You need to do this today to keep the current streak going
      </div>
      
      {/* Remove display: none snippet below to reveal toggle */}
      <div style={{display: "none"}} className="border">
        <ToggleSwitch
          id="showHiddenToggle"
          isOn={showHidden}
          handleToggle={handleHiddenToggle}
          onColor={lightGreen}
          offColor={darkGreen}
          onLabel="Show Hidden"
          offLabel="Hide Hidden"
        />
      </div>
    </div>
  );
};

export default Streaks;
