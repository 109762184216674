import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const History = () => {
  const [counterHistory, setCounterHistory] = useState();
  const [hasDescription, setHasDescription] = useState(false);
  const [allOnes, setAllOnes] = useState(false);
  let { id } = useParams();
  const { jwtToken } = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/login");
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    setAllOnes(true);

    fetch(
      `${process.env.REACT_APP_BACKEND}/counts/${id}/history`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setCounterHistory(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, jwtToken, navigate]);

  useEffect(() => {
    if (!counterHistory) {
      return;
    }
    counterHistory.history.forEach((entry) => {
      if (entry.description) {
        setHasDescription(true);
      }
      if (entry.value !== 1) {
        setAllOnes(false);
      }
    });
  }, [counterHistory]);

  return (
    <div>
      {counterHistory ? (
        <>
          <h2>History for {counterHistory.counterName}</h2>

          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Timestamp</th>
                {!allOnes && <th>Value</th>}
                {hasDescription && <th>Description</th>}
              </tr>
            </thead>
            <tbody>
              {counterHistory.history.map((c) => (
                <tr key={c.timestamp}>
                  <td>{new Date(c.timestamp).toLocaleString()}</td>
                  {!allOnes && <td>{c.value}</td>}
                  {hasDescription && <td>{c.description}</td>}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <p>Loading . . .</p>
      )}
    </div>
  );
};

export default History;
