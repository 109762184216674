import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import HeaderAndTag from "./form/HeaderAndTag";
import Input from "./form/Input";
import Select from "./form/Select";
import TimezoneSelect from "react-timezone-select";

const Register = () => {
  const [input, setInput] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    weekday: "SUNDAY",
  });

  const [error, setError] = useState({
    email: "",
    displayName: "",
    password: "",
    confirmPassword: "",
    weekday: "",
  });

  const { setJwtToken } = useOutletContext();
  const { setAlertClassName } = useOutletContext();
  const { setAlertMessage } = useOutletContext();
  const { toggleRefresh } = useOutletContext();
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const days = [
    { id: "SUNDAY", value: "Sunday" },
    { id: "MONDAY", value: "Monday" },
    { id: "TUESDAY", value: "Tuesday" },
    { id: "WEDNESDAY", value: "Wednesday" },
    { id: "THURSDAY", value: "Thursday" },
    { id: "FRIDAY", value: "Friday" },
    { id: "SATURDAY", value: "Saturday" },
  ];

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };
  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "email":
          if (!value) {
            stateObj[name] = "Please enter email.";
          }
          break;
        case "displayName":
          if (!value) {
            stateObj[name] = "Please enter Display Name.";
          }
          break;
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;
        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;
        default:
          break;
      }
      return stateObj;
    });
  };

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    // build the request payload
    let payload = {
      email: input.email,
      displayName: input.displayName,
      password: input.password,
      timezone: timezone.value ? timezone.value : timezone,
      firstDayOfWeek: input.weekday,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    };

    fetch(`${process.env.REACT_APP_BACKEND}/register`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.errorCode) {
          setAlertClassName("alert-danger");
          setAlertMessage(`${data.message} (${data.errorCode})`);
        } else {
          setJwtToken(data.access_token);
          setAlertClassName("d-none");
          setAlertMessage("");
          toggleRefresh(true);
          navigate("/");
        }
      })
      .catch((error) => {
        setAlertClassName("alert-danger");
        setAlertMessage(error);
      });
  };

  return (
    <div className="col-md-6 offset-md-3">
      <HeaderAndTag title="Register" />

      <form onSubmit={handleSubmit}>
        <Input
          title="Email Address"
          type="email"
          className="form-control"
          name="email"
          autoComplete="email-new"
          onChange={onInputChange}
          onBlur={validateInput}
          errorDiv={error.email ? "text-danger" : "d-none"}
          errorMsg={error.email}
        />

        <Input
          title="Display Name"
          type="displayName"
          className="form-control"
          name="displayName"
          autoComplete="displayName-new"
          onChange={onInputChange}
          onBlur={validateInput}
          errorDiv={error.email ? "text-danger" : "d-none"}
          errorMsg={error.email}
        />

        <Input
          title="Password"
          type="password"
          className="form-control"
          name="password"
          autoComplete="password-new"
          onChange={onInputChange}
          onBlur={validateInput}
          errorDiv={error.password ? "text-danger" : "d-none"}
          errorMsg={error.password}
        />
        <Input
          title="Verify Password"
          type="password"
          className="form-control"
          name="confirmPassword"
          autoComplete="verify-password-new"
          onChange={onInputChange}
          onBlur={validateInput}
          errorDiv={error.confirmPassword ? "text-danger" : "d-none"}
          errorMsg={error.confirmPassword}
        />

        <div className="mb-3">
          <label className="form-label">Timezone</label>
          <TimezoneSelect
            name="TimezoneSelect"
            value={timezone}
            onChange={setTimezone}
          />
        </div>

        <Select
          title={"First Day of Week"}
          name={"weekday"}
          options={days}
          onChange={onInputChange}
          errorMsg={"Please choose"}
          errorDiv={error.weekday ? "text-danger" : "d-none"}
        />

        <hr />

        <input type="submit" className="btn btn-primary" value="Register" />
      </form>
    </div>
  );
};

export default Register;
