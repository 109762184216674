import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import HeaderAndTag from "./form/HeaderAndTag";
import {
  StatusAheadIcon,
  StatusBehindIcon,
  StatusCompletedSuccessfullyIcon,
  StatusCompletedUnsuccessfullyIcon,
  StatusOnTrackIcon,
} from "./form/StatusCircles";

const GoalProgress = () => {
  const [currentGoals, setCurrentGoals] = useState([]);
  const [previousGoals, setPreviousGoals] = useState([]);
  const { jwtToken } = useOutletContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/login");
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/progress`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        var current = [];
        var previous = [];

        data.forEach((g) => {
          if (g.currentDay <= g.totalDays) {
            current.push(g);
          } else {
            previous.push(g);
          }
        });

        setCurrentGoals(current);
        setPreviousGoals(previous);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [jwtToken, navigate]);

  return (
    <div>
      <HeaderAndTag title="Goal Progress" />

      <div className="h3 mt-5">Current Goals</div>

      {currentGoals.length > 0 ? (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th />
              <th>Name</th>
              <th>Target</th>
              <th>Days Completed</th>
              <th>Days Remaining</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            {currentGoals.map((g) => (
              <tr key={g.id}>
                <td className="w-auto">
                  {Math.round((100 * g.currentCount) / g.targetCount) >
                  Math.round((100 * g.currentDay) / g.totalDays) ? (
                    <StatusAheadIcon />
                  ) : Math.round((100 * g.currentCount) / g.targetCount) <
                    Math.round((100 * g.currentDay) / g.totalDays) ? (
                    <StatusBehindIcon />
                  ) : (
                    <StatusOnTrackIcon />
                  )}
                </td>
                <td>
                  <Link to={`/goals/${g.id}`}>{g.name}</Link>
                </td>
                <td>
                  {g.currentCount}/{g.targetCount} (
                  {Math.round((100 * g.currentCount) / g.targetCount)}%)
                </td>
                <td>
                  {g.currentDay}/{g.totalDays} (
                  {Math.round((100 * g.currentDay) / g.totalDays)}%)
                </td>
                <td>{g.totalDays - g.currentDay}</td>
                <td>{new Date(g.end).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <>
          <div className="mb-3">
            No current goals, you should add one (or more)
          </div>
          <Link to={`/newgoal`} className="btn btn-primary">
            Add New Goal
          </Link>
        </>
      )}

      {previousGoals.length > 0 && (
        <>
          <div className="h3 mt-5">Previous Goals</div>

          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Target</th>
                <th>Ended</th>
              </tr>
            </thead>
            <tbody>
              {previousGoals.map(
                (g) =>
                  g.currentDay > g.totalDays && (
                    <tr key={g.id}>
                      <td className="w-auto">
                        {g.currentCount >= g.targetCount ? (
                          <StatusCompletedSuccessfullyIcon />
                        ) : (
                          <StatusCompletedUnsuccessfullyIcon />
                        )}
                      </td>
                      <td>
                        <Link to={`/goals/${g.id}`}>{g.name}</Link>
                      </td>
                      <td>
                        {g.currentCount}/{g.targetCount} (
                        {Math.round((100 * g.currentCount) / g.targetCount)}%)
                      </td>
                      <td className="w-auto">
                        {new Date(g.end).toLocaleDateString()}
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default GoalProgress;
