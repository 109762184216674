import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { darkGreen, lightGreen } from "./Colors";
import HeaderAndTag from "./form/HeaderAndTag";
import { DomapIcon, GraphIcon } from "./form/StatusCircles";
import ToggleSwitch from "./form/ToggleSwitch";
import { HistoryIcon } from "./form/StatusCircles";

const PublicCurrent = () => {
  const [counts, setCounts] = useState([]);
  const [thisPast, setThisPast] = useState(true);
  let { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(
      `${process.env.REACT_APP_BACKEND}/public/${id}/counts`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setCounts(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, navigate]);

  const handleToggle = () => {
    setThisPast(!thisPast);
  };

  const openGraphInNewTab = (event) => {
    openInNewTab(event, "graph");
  };

  const openDomapInNewTab = (event) => {
    openInNewTab(event, "domap");
  };

  const openInNewTab = (event, lastpart) => {
    event.preventDefault();

    if (!event.target.id) {
      return;
    }

    const url = `${process.env.REACT_APP_BACKEND}/public/${id}/counts/${event.target.id}/${lastpart}`;

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        let newWindow = window.open();
        newWindow.document.write(data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <HeaderAndTag title="Current Counts" />
      {!thisPast && (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Counter</th>
              <th>Past Day</th>
              <th>Past Week</th>
              <th>Past Month</th>
              <th>Past Year</th>
            </tr>
          </thead>
          <tbody>
            {counts.map((c) => (
              <tr key={c.id}>
                <td>{c.name}</td>
                <td>{c.pastDay}</td>
                <td>{c.pastWeek}</td>
                <td>{c.pastMonth}</td>
                <td>{c.pastYear}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {thisPast && (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Counter</th>
              <th>Today</th>
              <th>This Week</th>
              <th>This Month</th>
              <th>This Year</th>
            </tr>
          </thead>
          <tbody>
            {counts.map((c) => (
              <tr key={c.id}>
                <td>
                  {c.name}{" "}
                  {c.type === "MILESTONE" ? (
                    <Link to={`/history/${c.id}`}>
                      <HistoryIcon />
                    </Link>
                  ) : (
                    <a
                      onClick={
                        c.type === "DAILY"
                          ? openDomapInNewTab
                          : openGraphInNewTab
                      }
                      href="/"
                      id={c.id}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {c.type === "DAILY" ? (
                        <DomapIcon id={c.id} />
                      ) : (
                        <GraphIcon id={c.id} />
                      )}
                    </a>
                  )}
                </td>
                <td>{c.today}</td>
                <td>{c.thisWeek}</td>
                <td>{c.thisMonth}</td>
                <td>{c.thisYear}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className="border">
        <ToggleSwitch
          id="thisPastToggle"
          isOn={thisPast}
          handleToggle={handleToggle}
          onColor={lightGreen}
          offColor={darkGreen}
          onLabel="This"
          offLabel="Past"
        />
      </div>
    </div>
  );
};

export default PublicCurrent;
