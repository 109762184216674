import React, { useRef, useState, useEffect } from "react";

import styled from "styled-components";
import { darkGreen } from "../Colors";

const StyledContainer = styled.div`
  position: relative;
  border: 1px solid #c7c7c7;
  height: 128px;
  width: 256px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.1);
`;

const StyledList = styled.div`
  width: 100%;
  height: 96px;
  overflow-y: scroll;

  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0 !important;
  }
`;

// const StyledTrack = styled.div`
//   width: 33%;
//   flex-grow: 1;
//   height: 128px;
//   overflow-y: hidden;
//   border-right: ${(props) => (props.border ? "1px solid #c7c7c7" : "")};
// `;

const StyledTrack = styled.div`
  width: 33%;
  flex-grow: 1;
  height: 128px;
  overflow-y: hidden;
  border-right: "1px solid ${darkGreen}";
`;

const StyledNumber = styled.div`
  position: relative;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: center;
  background-color: darkGreen;
  color: white;

  &::after {
    content: "";
    background-color: white;
    position: absolute;
    height: 8px;
    width: 8px;
    bottom: -4px;
    border-radius: 100%;
    z-index: 5;
  }
`;

const StyledHeader = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #c7c7c7;
  background-color: #f9f9f9;
`;

const DurationTrack = ({ numbers, qualifier, border, onChange }) => {
  const list = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            return;
          }
          onChange(Number.parseFloat(entry.target.textContent));
        });
      },
      { threshold: 0.75 }
    );
    [...list.current.children].forEach((child) => {
      //console.log("child", child);
      observer.observe(child);
    });
  }, []);

  const renderNumberJSX = () => {
    return numbers.map((number, i) => (
      <StyledNumber key={qualifier + number}>{number}</StyledNumber>
    ));
  };

  return (
    <StyledTrack border={border}>
      <StyledHeader>{qualifier}</StyledHeader>
      <StyledList ref={list}>{renderNumberJSX()}</StyledList>
    </StyledTrack>
  );
};

const DurationInput = ({ childToParent }) => {
  const [duration, setDuration] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    const value = hours * 60 + minutes;
    setDuration(value);
    childToParent(value);
  }, [childToParent, hours, minutes]);

  return (
    <>
      <div className="row">
        <div className="col">{duration} minutes</div>
      </div>
      <div className="row">
        <StyledContainer className="col">
          <DurationTrack
            numbers={[...Array(24).keys()]}
            border
            qualifier="hour(s)"
            onChange={(value) => setHours(value)}
          />
          <DurationTrack
            numbers={[...Array(60).keys()]}
            qualifier="min(s)"
            onChange={(value) => setMinutes(value)}
          />
        </StyledContainer>
      </div>
    </>
  );
};

export default DurationInput;
