import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import TitleBanner from "./components/form/TitleBanner";
import MenuBar from "./components/form/MenuBar";
import Alert from "./components/Alert";

function App() {
  const [jwtToken, setJwtToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertClassName, setAlertClassName] = useState("d-none");

  const [tickInterval, setTickInterval] = useState();

  const navigate = useNavigate();

  const location = useLocation();

  const logOut = () => {
    console.log("logOut()");

    const requestOptions = {
      method: "GET",
      credentials: "include",
    };

    fetch(`${process.env.REACT_APP_BACKEND}/logout`, requestOptions)
      .catch((error) => {
        console.log("error logging out", error);
      })
      .finally(() => {
        setJwtToken("");
        toggleRefresh(false);
      });

    navigate("/");
  };

  const getPublicUrl = () => {
 

    const url = `${process.env.REACT_APP_BACKEND}/publicids`;

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        const myurl = "/public/" + data.publicId + "/counts"
        window.open(myurl);
        // console.log("myurl: "+myurl)
        // navigate(myurl);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleRefresh = useCallback(
    (status) => {
      console.log("clicked");

      if (status) {
        console.log("turning on ticking");
        let i = setInterval(() => {
          const requestOptions = {
            method: "GET",
            credentials: "include",
          };

          fetch(`${process.env.REACT_APP_BACKEND}/refresh`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              if (data.access_token) {
                setJwtToken(data.access_token);
              }
            })
            .catch((error) => {
              console.log("user is not logged in");
            });
        }, 600000);
        setTickInterval(i);
        console.log("setting tick interval to", i);
      } else {
        console.log("turning off ticking");
        console.log("turning off tickInterval", tickInterval);
        setTickInterval(null);
        clearInterval(tickInterval);
      }
    },
    [tickInterval]
  );

  useEffect(() => {
    if (jwtToken === "") {
      const requestOptions = {
        method: "GET",
        credentials: "include",
      };

      fetch(`${process.env.REACT_APP_BACKEND}/refresh`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.access_token) {
            setJwtToken(data.access_token);
            toggleRefresh(true);
          }
        })
        .catch((error) => {
          console.log("user is not logged in", error);
        });
    }
  }, [jwtToken, toggleRefresh]);

  return (
    <div className="container text-center">
      <div className="row">
        <div className="col text-end">
          {jwtToken === "" ? (
            location.pathname !== "/" && location.pathname === "/login" ? (
              <Link to="/register">
                <span className="badge bg-success">Register</span>
              </Link>
            ) : (
              <Link to="/login">
                <span className="badge bg-success">Login</span>
              </Link>
            )
          ) : (
            <>
            <a href="#!" onClick={getPublicUrl}>
              <span className="badge bg-info">Go to Public URL</span>
            </a>
            &nbsp;
            <a href="#!" onClick={logOut}>
              <span className="badge bg-danger">Logout</span>
            </a>
            </>
          )}
        </div>
        <hr className="mb-3" />
      </div>

      <TitleBanner />

      <MenuBar jwtToken={jwtToken} location={location} />

      <hr className="mb-3" />

      <div className="row">
        <Alert message={alertMessage} className={alertClassName} />

        <Outlet
          context={{
            jwtToken,
            setJwtToken,
            setAlertClassName,
            setAlertMessage,
            toggleRefresh,
          }}
        />
      </div>

      <div className="row">
        <hr className="mb-3 mt-3" />
        <div className="col">
          <a
            className="btn btn-outline-primary"
            href={`mailto:betterbydecember@yellowslicker.com`}
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
