import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({
  id,
  isOn,
  handleToggle,
  onColor,
  offColor,
  onLabel,
  offLabel,
}) => {
  return (
    <div className="row">
      <div className={`col text-end align-self-center ${!isOn &&"fw-bold"}`}>{offLabel}</div>

      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={id}
        type="checkbox"
      />
      <label
        style={{ background: isOn ? onColor : offColor }}
        className="react-switch-label col-md-8 mx-auto"
        htmlFor={id}
      >
        <span className={`react-switch-button`}></span>
      </label>
      <div className={`col text-start align-self-center ${isOn &&"fw-bold"}`}>{onLabel}</div>
    </div>
  );
};

export default ToggleSwitch;
