import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Swal from "sweetalert2";
import InfoBox from "./boxes/InfoBox";
import Card from "./cards/Card";
import CardGrid from "./cards/CardGrid";
import { darkGreen, red } from "./Colors";
import { DeleteIcon } from "./form/StatusCircles";
import ScopeLabel from "./scope/ScopeLabel";

const Goal = () => {
  const [goal, setGoal] = useState({ start: "", end: "" });
  let { id } = useParams();
  const { jwtToken } = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/login");
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/goals/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setGoal(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, jwtToken, navigate]);

  const confirmDelete = (event) => {
    const goalId = event.target.id
      ? event.target.id
      : event.target.ownerSVGElement.id;

    Swal.fire({
      title: "Delete goal?",
      text: "You cannot undo this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: darkGreen,
      cancelButtonColor: red,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Authorization", "Bearer " + jwtToken);

        const requestOptions = {
          method: "DELETE",
          headers: headers,
        };

        fetch(
          `${process.env.REACT_APP_BACKEND}/goals/${goalId}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.errorCode) {
              console.log(data.errorCode);
            } else {
              navigate("/goals");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const changeScope = (event) => {
    event.preventDefault();

    const inputOptions = {
      public: "Public",
      private: "Private",
      hidden: "Hidden",
    };

    Swal.fire({
      title: "Select Scope",
      text: "You cannot undo this action!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: darkGreen,
      cancelButtonColor: red,
      input: "radio",
      inputOptions: inputOptions,
      inputValue: "private",
    }).then((result) => {
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Authorization", "Bearer " + jwtToken);

        const requestOptions = {
          method: "PUT",
          headers: headers,
        };

        fetch(
          `${process.env.REACT_APP_BACKEND}/goals/${goal.id}/scope/${result.value}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              console.log(data.error);
            } else {
              setGoal({
                id: goal.id,
                name: goal.name,
                start: goal.start,
                end: goal.end,
                targetCount: goal.targetCount,
                counterId: goal.counterId,
                scope: data.scope,
                counterName: goal.counterName,
                currentCount: goal.currentCount,
                perDay: goal.perDay,
                perRemainingDay: goal.perRemainingDay,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return (
    <div>
      <h2 className="ps-5 mb-4 mt-2 float-left d-flex">Goal</h2>

      <div className="ps-5">
        <CardGrid>
          <>
            <Card key="name">
              <InfoBox title="Name" value={goal.name} />
            </Card>

            <Card key="scope">
              <ScopeLabel scope={goal.scope} editFunc={changeScope} />
            </Card>

            <Card key="counter">
              <Link to={`/counters/${goal.counterId}`}>
                <InfoBox title="Counter" value={goal.counterName} />
              </Link>
            </Card>

            <Card key="target">
              <InfoBox title="Target" value={goal.targetCount} />
            </Card>

            <Card key="start">
              <InfoBox
                title="Start"
                value={new Date(goal.start).toLocaleDateString()}
              />
            </Card>

            <Card key="end">
              <InfoBox
                title="End"
                value={new Date(goal.end).toLocaleDateString()}
              />
            </Card>

            <Card key="perday">
              <InfoBox title="Average Per Day" value={goal.perDay} />
            </Card>

            <Card key="remainingperday">
              <InfoBox
                title="Remaining Average Per Day"
                value={goal.perRemainingDay}
              />
            </Card>

            <Card key="delete">
              <InfoBox
                title="Delete"
                desc="think twice before deleting a goal"
                value={
                  <a
                    href="#!"
                    id={goal.id}
                    className="btn btn-outline-danger ms-2"
                    onClick={confirmDelete}
                    title={`Delete "${goal.name}" Goal`}
                  >
                    <DeleteIcon id={goal.id} size="50" />
                  </a>
                }
              />
            </Card>
          </>
        </CardGrid>
      </div>
    </div>
  );
};

export default Goal;
